import {defineStore} from 'pinia'
import axios from "axios";


export const useUserStore = defineStore('user', {
    state: () => ({
        isUserInfoLoading: false,
        isUserAuthorized: false,
        jwtToken: null,
        // {
        //     "name": "Tutor's name",
        //     "email": "tutor@flexow.co",
        //     "page_slug": "tutor",
        //     "meeting_link": "https://meet.google.com/somelink",
        // }
        userInfo: {},
    }),
    getters: {
        isAuthorized: (state) => state.isUserAuthorized,
        token: (state) => state.token,
        user: (state) => state.userInfo,
        userAuthEmail: (state) => state.authEmail,
    },
    actions: {
        checkAuth() {
            if (!this.isUserAuthorized && !this.jwtToken && localStorage.getItem('token')) {
                this.isUserAuthorized = true
                this.jwtToken = localStorage.getItem('token')
            }
            if (this.isUserAuthorized && this.jwtToken) {
                this.getUserInfo()
            }
            return Promise.resolve()
        },
        getUserInfo() {
            this.isUserInfoLoading = true;
            return axios.get('/admin/user-info').then((response) => {
                Object.assign(this.userInfo, response.data.data.profile)
                return response.data.data;
            }).finally(() => {
                this.isUserInfoLoading = false;
            });
        },
        sendEmailCode(email) {
            this.authEmail = email;
            return axios.post('/admin/send-email-code', {email}).then((response) => {
                return response.data;
            });
        },
        verifyEmailCode(email, code) {
            return axios.post('/admin/verify-email-code', {
                email: email,
                code: code,
            }).then((response) => {
                this.isUserAuthorized = true;
                this.jwtToken = response.data.token;
                return response.data;
            });
        },
        logout() {
            this.isUserAuthorized = false;
            this.jwtToken = null;
            this.userInfo = {};
            localStorage.removeItem('token');
        },
    },
})
