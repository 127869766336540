<script setup>
import {useUserStore} from "@/stores/user";
import {onMounted} from "vue";
import {useRouter} from "vue-router";
import BaseOverlayLoader from "@/components/ui-kit/BaseOverlayLoader.vue";
import TheLayoutSidebar from "@/components/TheLayoutSidebar.vue";

const userStore = useUserStore()
const router = useRouter()

onMounted(() => {
  if(Object.keys(userStore.userInfo).length){
    return;
  }
  userStore.checkAuth().then(() => {
    if (!userStore.isUserAuthorized) {
      router.push({name: 'login'})
    }
  });
})
</script>

<template>
<div class="auth-layout">
  <TheLayoutSidebar/>
  <main>
    <slot/>
  </main>
  <BaseOverlayLoader
      v-if="userStore.isUserInfoLoading"
      blur="5"
  />
</div>
</template>

<style lang="scss" scoped>
.auth-layout{
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  aside{
    width: 300px;
    background-color: #f5f5f5;
    padding: 25px;
  }
  main{
    flex: 1;
    overflow-y: auto;
    padding: 25px;
  }
}
</style>
