<script setup>

const links = [
  {
    name: 'Home',
    route: 'home',
    icon: 'home'
  },
  {
    name: 'Users',
    route: 'users',
    icon: 'info'
  },
  {
    name: 'Logout',
    route: 'logout',
    icon: 'phone'
  },
]
</script>

<template>
  <aside class="layout-sidebar">
    <ul class="layout-sidebar__links">
      <li
          v-for="link in links"
          :key="link.name"
          class="layout-sidebar__link"
      >
        <router-link
            :to="{name: link.route}"
            class="layout-sidebar__link-item"
        >
          <span
              v-if="link.icon"
              class="layout-sidebar__link-icon"
          >
            <i :class="'icon-' + link.icon"/>
          </span>
          <span class="layout-sidebar__link-name">
            {{ link.name }}
          </span>
        </router-link>
      </li>
    </ul>
  </aside>
</template>

<style lang="scss" scoped>
.layout-sidebar {
  &__links {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__link {
    margin-bottom: 1rem;
  }

  &__link-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &__link-icon {
    margin-right: 1rem;
  }

  &__link-name {
    font-size: 1.2rem;
  }
}
</style>
