import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {useUserStore} from "@/stores/user";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/LoginView.vue')
  },
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/ConfirmEmailView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => {
      useUserStore().logout()
      window.location.href = '/login'
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchUsersView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
