<script setup>

import {computed} from "vue";

const props = defineProps({
  size: {
    type: [String, Number],
    default: '48',
    required: false
  },
  strokeWidth: {
    type: [String, Number],
    default: '',
    required: false
  },
})

const style = computed(() => {
  const strokeWidth = props.strokeWidth || Number(props.size) / 8
  return {
    width: `${props.size}px`,
    height: `${props.size}px`,
    'border-width': `${strokeWidth}px`
  }
})

</script>
<template>
  <span
      class="base-loader"
      :style="style"
  />
</template>
