<script setup>
import {useAppStore} from "@/stores/app";

const appStore = useAppStore()
</script>
<template>
  <router-view/>

  <div class="app-messages">
    <div
        v-for="(message, messIdx) in appStore.messages"
        :key="messIdx + message.text"
        class="base-alert-message"
        :class="`base-alert-message--${message.type}`"
    >
      <span>{{ message.text }}</span>
      <img
          src="/img/icon/close.svg"
          alt="x"
          @click="appStore.removeMessage(message)"
      >
    </div>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.app-messages {
  position: fixed;
  max-width: 320px;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 4px;

  .base-alert-message {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-color: #2c3e50;
    color: #ffffff;

    &--error {
      background-color: #5b0808;
      color: #ffffff;
    }

    img {
      width: 24px;
      height: 24px;
      filter: invert(1);
    }
  }
}
</style>
