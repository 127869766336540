import {defineStore} from 'pinia'

export const useAppStore = defineStore('appStore', {
    state: () => ({
        // Notification messages are stored here
        // format: {text: 'Some message', timeout: 5000, type: 'success|error'}
        messages: []
    }),
    getters: {},
    actions: {
        /**
         * Add a new message to the messages array
         * will show up in the notifications
         * and disappear after the timeout
         *
         * @param text
         * @param type
         * @param timeout
         */
        addMessage(text, type = 'success', timeout = 5000) {
            const message = {
                text: text,
                timeout: timeout,
                type: type,
            }
            this.messages.push(message)
            if (timeout !== 0) {
                setTimeout(() => {
                    this.messages.splice(this.messages.indexOf(message), 1)
                }, timeout)
            }
        },
        removeMessage(message) {
            this.messages = this.messages.filter((item) => item !== message)
        },
        /**
         * Handle axios error message and add it to the messages array
         * @param error
         */
        handleAxiosErrorMessage(error) {
            console.log('handleAxiosErrorMessage', error.response?.data)
            if (error.response?.data?.message) {
                console.log('add single message', error.response.data.message)
                this.addMessage(error.response.data.message, 'error');
            } else if (error.response?.data?.errors) {
                console.log('add multiple messages', error.response.data.errors)
                Object.values(error.response.data.errors).forEach((error) => {
                    this.addMessage(error[0], 'error');
                });
            }else {
                console.log('add error message', error.message)
                this.addMessage(error.message, 'error');
            }
        },
    },
})
