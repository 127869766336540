import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from 'pinia'
import axios from "axios";
import './assets/scss/main.scss'



/* Axios setup */
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
if (localStorage.getItem('token')) {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
    // set up x-lang header
    axios.defaults.headers.common['x-lang'] = localStorage.getItem('lang') || 'en';
}
// On 401 error, clear the token and redirect to login
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            console.log('401 error', error.response)
            localStorage.removeItem('token');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);


const app = createApp(App)

app.use(createPinia())
app.use(router)
app.mount('#app')
